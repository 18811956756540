import { defineStore } from 'pinia'
import legalsQuery from '../apollo/queries/pages/generic/legals.gql'
import termsQuery from '../apollo/queries/pages/generic/terms.gql'
import cookiesQuery from '../apollo/queries/pages/generic/cookies.gql'
import faqQuery from '../apollo/queries/pages/generic/faq.gql'

export const useGenericStore = defineStore('generic', {
  state: () => ({
    pages: {
      legals: {},
      terms: {},
      cookies: {},
      faq: {}
    }
  }),
  getters: {
    getLegalsData: (state) => {
      return state.pages.legals ? state.pages.legals?.legal?.data?.attributes : null
    },
    getTermsData: (state) => {
      return state.pages.terms ? state.pages.terms?.term?.data?.attributes : null
    },
    getCookiesData: (state) => {
      return state.pages.cookies ? state.pages.cookies?.cookie?.data?.attributes : null
    },
    getFaqData: (state) => {
      return state.pages.faq ? state.pages.faq?.faq?.data?.attributes : null
    }
  },
  actions: {
    async requestLegalsData() {
      if (Object.keys(this.pages.legals).length !== 0) return

      const { data } = await useAsyncQuery(legalsQuery) // eslint-disable-line no-undef

      if (data) this.pages.legals = data
    },
    async requestTermsData() {
      if (Object.keys(this.pages.terms).length !== 0) return

      const { data } = await useAsyncQuery(termsQuery) // eslint-disable-line no-undef

      if (data) this.pages.terms = data
    },
    async requestCookiesData() {
      if (Object.keys(this.pages.cookies).length !== 0) return

      const { data } = await useAsyncQuery(cookiesQuery) // eslint-disable-line no-undef

      if (data) this.pages.cookies = data
    },
    async requestFaqData() {
      if (Object.keys(this.pages.faq).length !== 0) return

      const { data } = await useAsyncQuery(faqQuery) // eslint-disable-line no-undef

      if (data) this.pages.faq = data
    }
  }
})
